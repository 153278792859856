// I'm kind of on the fence about making routes iterable
export const navRoutes = [
  // { path: "/", text: "HOME", private: false },
  // { path: "/impact", text: "IMPACT", private: false },
  // { path: "/contact", text: "Team", private: false },
  { path: "/about", text: "ABOUT", private: false },
  { path: "/", text: "HQ", private: true },
  { path: "/mission", text: "MISSION", private: true },
  // { path: "/profile", text: "PROFILE", private: true },
  { path: "https://missiongaia.myshopify.com/", text: "SHOP", private: false },
  { path: "https://missiongaia.myshopify.com/", text: "SHOP", private: true },
];
